import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
  timeout: 300000,
  headers: {
    headerKey: 'qLI{?gfe@@k6Uus}+',
  },
})

// Add a request interceptor
instance.interceptors.request.use(config => {
  const token = `Bearer ${localStorage.getItem('accessToken')}`
  config.headers.Authorization = token

  return config
},
error => {

  return Promise.reject(error)
},)
export default instance
