import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.visible)?_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{style:(_vm.type == 'error' && 'color:red; text-align: center;')},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VCardActions,[_c(VSpacer),(_vm.type != 'error')?_c('div',[_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.accept}},[_vm._v(" Yes ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.reject}},[_vm._v(" No ")])],1):(_vm.type == 'error')?_c('div',[_c(VBtn,{on:{"click":_vm.accept}},[_vm._v(" OK ")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }