<template>
    <v-dialog v-model="visible" width="500">
      <!-- Dialog Content -->
      <VCard v-if="visible">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <VCardText :style="type == 'error' && 'color:red; text-align: center;'">
          {{ message }}
        </VCardText>
  
        <VCardActions>
          <VSpacer />
          <div v-if="type != 'error'">
            <VBtn @click="accept" color="error"> Yes </VBtn>
            <VBtn @click="reject" color="success"> No </VBtn>
          </div>
          <div v-else-if="type == 'error'">
            <VBtn @click="accept"> OK </VBtn>
          </div>
        </VCardActions>
      </VCard>
    </v-dialog>
  </template>
  
  <script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    data() {
      return {
        visible1: true,
      }
    },
    emits: ['accept', 'reject'],
    props: ['visible', 'message', 'title', 'type'],
    methods: {
      accept() {
        this.$emit('accept')
      },
      
      reject() {
        this.$emit('reject')
      },
    },
  })
  </script>
