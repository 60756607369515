import moment from 'moment'

class Main {
    constructor(){

    }
        static formatDate(date){
          const momentObj =moment(date) 
          return momentObj.format('DD MMM,YYYY');
          
      }

      static SaniIdDateFormat(date){
        const momentObj =moment(date) 
        return momentObj.format('YYMMDD');
      }
      
      static isEmpty(value) {
        if (value === null || value === undefined || value === '')
          return true
        
        return !!(Array.isArray(value) && value.length === 0)
      }

      static resolveUserRoleVariant(role) {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'error'
    
        return 'primary'
      }
    
      static resolveUserRoleIcon (role) {
        if (role === 'subscriber') return 'mdi-account-outline'
        if (role === 'Super-Admin') return 'mdi-shield-check'
        if (role === 'author') return 'mdi-cog-outline'
        if (role === 'maintainer') return 'mdi-database-outline'
        if (role === 'editor') return 'mdi-pencil-outline'
        if (role === 'admin') return 'mdi-dns-outline'
    
        return 'mdi-account-outline'
      }
    
      static resolveUserStatusVariant(status) {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
    
        return 'primary'
      }
    
      static resolveUserTotalIcon(total) {
        if (total === 'Total Users') return { icon: 'mdi-account-outline', color: 'primary' }
        if (total === 'Paid Users') return { icon: 'mdi-account-plus-outline', color: 'error' }
        if (total === 'Active Users') return { icon: 'mdi-account-check-outline', color: 'success' }
        if (total === 'Pending Users') return { icon: 'mdi-account-remove-outline', color: 'warning' }
    
        return { icon: 'mdi-account-outline', color: 'primary' }
      }





}


export default Main ;
