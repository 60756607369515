import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import app from './app'
import messageStore from './messageStore'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations,
  actions,
  modules: {
    appConfig: appConfigStoreModule,
    messageStore,
    app,
  },
})
