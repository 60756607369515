import axios from './axios-auth'

export default {
  login({ commit, state, dispatch }, authData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login', {
          email: authData.email,
          password: authData.password,
        })
        .then(res => {
          const userObJ = {
            token: res.data.data.user.api_token,
          }
          const userData =
            '{"id":1,"fullName":"John Doe","username":"johndoe","avatar":"/img/1.e2938115.png","email":"admin@materio.com","role":"admin"}'
          const userAbility = '[{"action":"manage","subject":"all"}]'
          localStorage.setItem('userData', userData)
          localStorage.setItem('userAbility', userAbility)
          localStorage.setItem('accessToken', res.data.data.user.api_token)
          localStorage.setItem('userDataInfo', JSON.stringify(res.data.data.user))
          localStorage.removeItem('Auth')
          commit('authUser', userObJ)
          resolve(true)
        })
        .catch(error => {
          // Error
          if (error) {
            console.log({ error })
            const messageObj = {
              text: 'Invalid Email or Password',
              className: 'error',
              isActive: false,
            }
            reject(messageObj)
          }
        })
    })
  },
  fetchOrderNumbers({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/orders/order-numbers`)
        .then(response => {
          commit('app/UPDATE_ORDER_NUMBERS', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchProblems({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`orders/un-read-reports`)
        .then(response => {
          commit('app/UPDATE_ORDER_REPORTS_NUMBERS', response.data.data.total)
          resolve(response.data.data.total)
        })
        .catch(error => reject(error))
    })
  },
  fetchOTP({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      fetch('http://josmsservice.com/sms/api/GetBalance.cfm?AccName=aklatbyoot&AccPass=fI8cM@8rA8g@D8gI0h', {
        method: 'GET',
        headers: [
          ['Access-Control-Allow-Origin', '*'],
          ['Access-Control-Allow-Methods', 'DELETE, POST, GET, OPTIONS'],
          ['Access-Control-Allow-Headers', 'Content-Type, Authorization, X-Requested-With'],
        ],
      })
        .then(response => {
          console.log('response', response)
          commit('app/UPDATE_OTP_REMAINING', response)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
}
