import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","width":_vm.width},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"text-center"},[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,{attrs:{"align":"center"}},[_c(VContainer,[_vm._t("content")],2)],1),_c(VCardActions,[_c(VSpacer),(_vm.type != 'question')?_c(VBtn,{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":function($event){return _vm.$emit('update:show-dialog', false)}}},[_vm._v(" Close ")]):_c('div',[_c(VBtn,{staticClass:"me-6",attrs:{"color":"info","variant":"text"},on:{"click":function($event){_vm.dialog_action(); _vm.$emit('update:show-dialog', false)}}},[_vm._v(" Yes ")]),_c(VBtn,{attrs:{"color":"error","variant":"text"},on:{"click":function($event){return _vm.$emit('update:show-dialog', false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }