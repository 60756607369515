const apps = [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Invoice ——————————————————
  //

  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-invoice-list',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-invoice-list',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-invoice-list',
    },
  },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/user/view/:id',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-user-list',
    },
  },

  //
  //* ——— Emplpoyees ——————————————————
  //

  {
    path: '/apps/employees/list',
    name: 'apps-employees-list',
    component: () => import('@/views/apps/employees/employees-list/EmployeesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/employees/view/:id',
    name: 'apps-employees-view',
    component: () => import('@/views/apps/employees/employees-view/EmployeesView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-employees-list',
    },
  },

  //
  //* ——— Company ——————————————————
  //

  {
    path: '/apps/company/list',
    name: 'apps-company-list',
    component: () => import('@/views/apps/company/company-list/CompanyList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/company/view/:id',
    name: 'apps-company-view',
    component: () => import('@/views/apps/company/company-view/CompanyView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-company-list',
    },
  },

  //
  //* ——— Kitchens ——————————————————
  //

  {
    path: '/apps/kitchens/list',
    name: 'apps-kitchens-list',
    component: () => import('@/views/apps/kitchens/kitchens-list/KitchenList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/kitchens/view/:id',
    name: 'apps-kitchens-view',
    component: () => import('@/views/apps/kitchens/kitchens-view/KitchenView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-kitchens-list',
    },
  },

  //
  //* ——— Chef ——————————————————
  //

  {
    path: '/apps/chef/list',
    name: 'apps-chef-list',
    component: () => import('@/views/apps/chef/chef-list/ChefList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/chef/view/:id',
    name: 'apps-chef-view',
    component: () => import('@/views/apps/chef/chef-view/ChefView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-chef-list',
    },
  },

  //
  //* ——— Charge ——————————————————
  //

  {
    path: '/apps/charge/list',
    name: 'apps-charge-employees',
    component: () => import('@/views/apps/charge/charge-list/ChargeEmployees.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-company-list',
    },
  },
  {
    path: '/apps/charge-for-users/list',
    name: 'apps-charge-for-users-list',
    component: () => import('@/views/apps/charge-for-users/users-list/UserList.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-charge-for-users-list',
    },
  },

  //
  //* ——— Problems ——————————————————
  //

  {
    path: '/apps/problem/list',
    name: 'apps-problem-list',
    component: () => import('@/views/apps/problem/problem-list/ProblemList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/problem/view/:id',
    name: 'apps-problem-view',
    component: () => import('@/views/apps/problem/problem-view/ProblemView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-problem-list',
    },
  },

  //

  //
  //* ——— Charge History ——————————————————
  //

  {
    path: '/apps/charge-history/list',
    name: 'apps-charge-history-list',
    component: () => import('@/views/apps/charge history/charge-history-list/ChargeHistoryList.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-company-list',
    },
  },
  {
    path: '/apps/charge-history/view/:id',
    name: 'apps-charge-view',
    component: () => import('@/views/apps/charge history/charge-view/ChargeHistoryView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-company-list',
    },
  },

  //

  //
  //* ——— Sizes ——————————————————
  //

  {
    path: '/apps/sizes/list',
    name: 'apps-sizes-list',
    component: () => import('@/views/apps/sizes/sizes-list/SizesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/promotion/list',
    name: 'apps-promotion-list',
    component: () => import('@/views/apps/promotion/promotion-list/PromotionList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/promotion/view/:id',
    name: 'apps-promotion-view',
    component: () => import('@/views/apps/promotion/promotion-view/PromotionView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-promotion-list',
    },
  },
  {
    path: '/apps/sizes/view/:id',
    name: 'apps-size-view',
    component: () => import('@/views/apps/sizes/size-view/SizeView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-sizes-list',
    },
  },

  //

  //
  //* ——— Meals ——————————————————
  //

  {
    path: '/apps/meals/list',
    name: 'apps-meals-list',
    component: () => import('@/views/apps/meals/meals-list/MealsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/meals/view/:id',
    name: 'apps-meal-view',
    component: () => import('@/views/apps/meals/meals-view/MealView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-meals-list',
    },
  },

  //

  //
  //* ——— Pre-Orders ——————————————————
  //

  {
    path: '/apps/preorders/list',
    name: 'apps-preorders-list',
    component: () => import('@/views/apps/preorders/pre-list/PreList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/preorders/view/:id',
    name: 'apps-pre-view',
    component: () => import('@/views/apps/preorders/pre-view/PreView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-preorders-list',
    },
  },

  //

  //
  //* ——— Items ——————————————————
  //

  {
    path: '/apps/items/list',
    name: 'apps-items-list',
    component: () => import('@/views/apps/items/items-list/ItemsList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/items/view/:id',
    name: 'apps-item-view',
    component: () => import('@/views/apps/items/items-view/ItemView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-items-list',
    },
  },

  //
  //
  //* ——— Inventory ——————————————————
  //

  {
    path: '/apps/inventory/history',
    name: 'apps-inventory-history',
    component: () => import('@/views/apps/inventory/inventory-history/InventoryHistory.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/inventory/list',
    name: 'apps-inventory-list',
    component: () => import('@/views/apps/inventory/inventory-list/InventoryList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/inventory/view/:id',
    name: 'apps-inventory-view',
    component: () => import('@/views/apps/inventory/inventory-view/InventoryView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-inventory-list',
    },
  },

  //

  //
  //* ——— Packages ——————————————————
  //

  {
    path: '/apps/packages/list',
    name: 'apps-packages-list',
    component: () => import('@/views/apps/packages/packages-list/PackagesList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/packages/view/:id',
    name: 'apps-package-view',
    component: () => import('@/views/apps/packages/packages-view/PackageView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-packages-list',
    },
  },

  //
  //* ——— Events ——————————————————
  //

  {
    path: '/apps/events/list',
    name: 'apps-events-list',
    component: () => import('@/views/apps/events/events-list/EventsList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/events/view/:id',
    name: 'apps-event-view',
    component: () => import('@/views/apps/events/events-view/EventView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-events-list',
    },
  },
  //

  //
  //* ——— Daily Messages ——————————————————
  //

  {
    path: '/apps/messages/list',
    name: 'apps-messages-list',
    component: () => import('@/views/apps/messages/messages-list/MessagesList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/messages/view/:id',
    name: 'apps-message-view',
    component: () => import('@/views/apps/messages/messages-view/MessageView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-messages-list',
    },
  },

  //
  //* ——— Promo Codes ——————————————————
  //

  {
    path: '/apps/codes/list',
    name: 'apps-codes-list',
    component: () => import('@/views/apps/codes/codes-list/CodesList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/codes/history',
    name: 'apps-codes-history',
    component: () => import('@/views/apps/codes/codes-list/CodesHistoryList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/codes/view/:id',
    name: 'apps-code-view',
    component: () => import('@/views/apps/codes/codes-view/CodeView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-codes-list',
    },
  },
  //* ——— Chat ——————————————————
  //

  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },

  //

  //
  //* ——— Orders ——————————————————
  //

  {
    path: '/apps/orders/placed/list',
    name: 'apps-orders-placed-list',
    component: () => import('@/views/apps/orders/orders-list/OrdersPlacedList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/orders/prepared/list',
    name: 'apps-orders-prepared-list',
    component: () => import('@/views/apps/orders/orders-list/OrdersPreparedList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/orders/delivered/list',
    name: 'apps-orders-delivered-list',
    component: () => import('@/views/apps/orders/orders-list/OrdersDeliveredList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/orders/canceled/list',
    name: 'apps-orders-canceled-list',
    component: () => import('@/views/apps/orders/orders-list/OrdersCanceledList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/orders/view/:id',
    name: 'apps-order-placed-view',
    component: () => import('@/views/apps/orders/orders-view/OrderView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-orders-placed-list',
    },
  },
  {
    path: '/apps/orders/view/:id',
    name: 'apps-order-prepared-view',
    component: () => import('@/views/apps/orders/orders-view/OrderView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-orders-prepared-list',
    },
  },
  {
    path: '/apps/orders/view/:id',
    name: 'apps-order-delivered-view',
    component: () => import('@/views/apps/orders/orders-view/OrderView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-orders-delivered-list',
    },
  },
  {
    path: '/apps/orders/view/:id',
    name: 'apps-order-canceled-view',
    component: () => import('@/views/apps/orders/orders-view/OrderView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-orders-canceled-list',
    },
  },

  //* ——— Email ——————————————————
  //

  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default apps
