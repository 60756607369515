export default {
    namespaced: true,
    state: {
      type: null,
      message: null,
      visible: false,
      error: {
        openMessageDialog: false,
        title: '',
        message: '',
        type: '',
      },
    },
    getters: {
      get_messageStore_error(state) {
        return state.error
      }
    },
    mutations: {
      UPDATE_TYPE(state, value) {
        state.type = value
      },
      UPDATE_MESSAGE(state, value) {
        state.message = value
      },
      UPDATE_VISIBLE(state, value) {
        state.visible = value
      },
      UPDATE_ERROR(state, obj) {
        state.error = obj
      },
    }
  }
  