// axios
import axios from 'axios'
import Vue from 'vue'
import { Response } from "@/services";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
  timeout: 80000,
  headers: {
    headerKey: 'qLI{?gfe@@k6Uus}+',
    'Accept-Language': 'en',
  },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => {

    return Promise.reject(error)
  },
)

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    Response.handlingError(error)
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
