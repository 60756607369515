<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent :width=width>
      <v-card class="text-center">
        <v-card-title>
          <span class="text-h5">{{title}}</span>
        </v-card-title>
        <v-card-text align="center">
          <v-container>
            <slot name="content"> </slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="type != 'question'" color="blue-darken-1" variant="text" @click="$emit('update:show-dialog', false)"> Close </v-btn>
          <div v-else>
            <v-btn class="me-6" color="info" variant="text" @click="dialog_action(); $emit('update:show-dialog', false)"> Yes </v-btn>
            <v-btn color="error" variant="text" @click="$emit('update:show-dialog', false)"> Cancel </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  emits: ['dialog_action'],
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    width: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  methods:{
    dialog_action(){
      this.$emit('dialog_action')
    }
  }
}
</script>
