import Main from "./Main";
import router from "@/router";
import store from '@/store'

class Response extends Main {
  constructor() {
    super();
  }

  static handlingError(error) {
    switch (error?.response?.status || error.message) {
      case "Network Error": //this case happen when network is offline, the message return by axios
        this.network(error);
        break;
      case 400:
        // this.errorFixedMessage('The server cannot or will not process the request due to something that is perceived to be a client error',400);
        break;
      case 401:
        this.error401();
        break;
      case 403:
        this.error403();
        break;
      case 404:
        this.error404();
        break;
      case 408:
        this.errorFixedMessage('Request Timeout',408);
        break;
      case 413:
        this.errorFixedMessage("The file you are trying to upload is too large",413);
        break;
      case 422:
        // this.errorFixedMessage('The request was well-formed but was unable to be followed due to semantic errors.',422);
        break;
      case 429:
        this.errorFixedMessage('The user has sent too many requests in a given amount of time ("rate limiting").',429);
        break;
      default:
        error?.response?.status>=500 ? this.errorFixedMessage('Internal Server Error',error?.response?.status):this.errorFixedMessage('Internal Server Error','error');
    }
  }

  static network(error) {
    store.commit('messageStore/UPDATE_ERROR', {
      openMessageDialog: true,
      title: "Error",
      message: error?.message,
      type: 'error',
    })
  }
  static errorFixedMessage(message,status) {
    store.commit('messageStore/UPDATE_ERROR', {
      openMessageDialog: true,
      title: `Error-${status}`,
      message: message,
      type: 'error',
    })
  }

  static error401() {
    localStorage.setItem(
      "Auth",
      "Automatically Logout Due Unauthorization Usage, Please Login Again."
    );
    // Remove "userData" from localStorage
    localStorage.removeItem("userDataInfo");
    // Remove "accessToken" from localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userAbility");
    if (router.currentRoute.name !== 'auth-login') {
      router.push({ path: "/login" });
    }
  }

  static error403() {
    router.push({ name: "not-authorized" });
  }

  static error404() {
    router.push({ name: 'error-404' });
  }

}

export default Response;
