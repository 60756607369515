export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    placed: 0,
    preparing: 0,
    reports: 0,
    otp_remaining: 0,
  },
  getters: {
    getOrderPlaced(state) {
      return state.placed
    },
    getOrderPreparing(state) {
      return state.preparing
    },
    getOrderReports(state) {
      return state.reports
    },
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    UPDATE_ORDER_NUMBERS(state, Obj) {
      state.placed = Obj.placed
      state.preparing = Obj.preparing
    },
    UPDATE_ORDER_REPORTS_NUMBERS(state, number) {
      state.reports = number
    },
    UPDATE_OTP_REMAINING(state, number) {
      state.otp_remaining = number
    },
    UPDATE_IS_LOADING(state, value) {
      state.isLoading = value
    },
  },
  actions: {},
}
