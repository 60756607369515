import '@/@fake-db/db'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import VueGeoLocation from 'vue-browser-geolocation'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VuetifyTimeSelect from 'vuetify-time-select'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import baseDialog from '@/views/components/baseDialog.vue'

Vue.config.productionTip = false
Vue.use(VueTelInputVuetify, {
  vuetify,
})
Vue.use(VuetifyTimeSelect, {
  vuetify,
})
Vue.use(VueGeoLocation)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBdOAmrXcck49n-yyzSH6MxC1lMbT0hOUw',
    libraries: 'places',
  },
  installComponents: true,
})

Vue.component('vuetify-time-select', VuetifyTimeSelect)
Vue.component('google-map', VueGoogleMaps.Map)
Vue.component('google-marker', VueGoogleMaps.Marker)
Vue.component('google-search', VueGoogleMaps.Autocomplete)
Vue.component('gmap-info-window', VueGoogleMaps.InfoWindow)
Vue.component('google-polyline', VueGoogleMaps.Polyline)
Vue.component('base-Dialog', baseDialog)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
